import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home/:id',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Mining.vue')
  },
  {
    path: '/metallurgy',
    name: 'Metallurgy',
    component: () => import('../views/Metallurgy.vue')
  },
  {
    path: '/metallurgy/:id',
    name: 'Metallurgy',
    component: () => import('../views/Metallurgy.vue')
  }, {
    path: '/mining/:id',
    name: 'Mining',
    component: () => import('../views/Mining.vue')
  },
  {
    path: '/mining',
    name: 'Mining',
    component: () => import('../views/Mining.vue')
  },
  {
    path: '/railroad',
    name: 'Railroad',
    component: () => import('../views/Railroad.vue')
  },
  {
    path: '/railroad/:id',
    name: 'Railroad',
    component: () => import('../views/Railroad.vue')
  },
  {
    path: '/repair',
    name: 'Repair',
    component: () => import('../views/Repair.vue')
  },
  {
    path: '/repair/:id',
    name: 'Repair',
    component: () => import('../views/Repair.vue')
  },
  {
    path: '/oilandgas',
    name: 'OilAndGas',
    component: () => import('../views/OilAndGas.vue')
  },
  {
    path: '/oilandgas/:id',
    name: 'OilAndGas',
    component: () => import('../views/OilAndGas.vue')
  },
  {
    path: '/food/:id',
    name: 'Food',
    component: () => import('../views/Food.vue')
  },
  {
    path: '/food',
    name: 'Food',
    component: () => import('../views/Food.vue')
  },
  {
    path: '/terms/:id',
    name: 'Terms',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/privacy/:id',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/kontakt/:id',
    name: 'Kontakt',
    component: () => import('../views/Kontakt.vue')
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import('../views/Kontakt.vue')
  },
  {
    path: '/imprint/:id',
    name: 'Imprint',
    component: () => import('../views/Imprint.vue')
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import('../views/Imprint.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
