<template>
  <section id="contact-us" class="gray">
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <h2><span class="bold">Connect</span> With Us</h2>
          <div class="orange-line center">
            <div class="orange"></div>
            <div class="line"></div>
          </div>
          <h3 class="contact-subhead">Send us your requests with technical specifications to receive a promt reply from our industry specialists</h3>
        </v-col>
        <v-col cols="12" md="4">
          <v-row>
            <v-col cols="3" md="3">
              <svg style="max-width: 100%;" width="76" height="70" viewBox="0 0 76 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M76 35L68.5 41.9282L68.5 28.0718L76 35Z" fill="#EEB900"/>
                <rect x="1" y="1" width="68" height="68" stroke="#EEB900" stroke-width="2"/>
                <path d="M20 12V14H15V48H34V58H55V32H50V30H41V14H36V12H20ZM19 18H22V20H19V18ZM24 18H27V20H24V18ZM29 18H32V20H29V18ZM34 18H37V20H34V18ZM19 22H22V24H19V22ZM24 22H27V24H24V22ZM29 22H32V24H29V22ZM34 22H37V24H34V22ZM19 26H22V28H19V26ZM24 26H27V28H24V26ZM29 26H32V28H29V26ZM34 26H37V28H34V26ZM19 30H22V32H19V30ZM24 30H27V32H24V30ZM29 30H32V32H29V30ZM34 30H37V32H34V30ZM41 32H48V34H53V56H36V34H41V32ZM19 34H22V36H19V34ZM24 34H27V36H24V34ZM29 34H32V36H29V34ZM38 36V38H41V36H38ZM43 36V38H46V36H43ZM48 36V38H51V36H48ZM19 38H22V40H19V38ZM24 38H27V40H24V38ZM29 38H32V40H29V38ZM38 40V42H41V40H38ZM43 40V42H46V40H43ZM48 40V42H51V40H48ZM19 42H22V44H19V42ZM24 42H27V44H24V42ZM29 42H32V44H29V42ZM38 44V46H41V44H38ZM43 44V46H46V44H43ZM48 44V46H51V44H48ZM38 48V50H41V48H38ZM43 48V50H46V48H43ZM48 48V50H51V48H48ZM38 52V54H41V52H38ZM43 52V54H46V52H43ZM48 52V54H51V52H48Z" fill="black"/>
              </svg>
            </v-col>
            <v-col cols="9" md="9">
              <h3> Corporate Office </h3>
              <p>
                Bogenstr. 15 <br>
                47799 Krefeld
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-row>
            <v-col cols="3" md="3">
              <svg style="max-width: 100%;" width="76" height="70" viewBox="0 0 76 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M76 35L68.5 41.9282L68.5 28.0718L76 35Z" fill="#EEB900"/>
                <rect x="1" y="1" width="68" height="68" stroke="#EEB900" stroke-width="2"/>
                <path d="M10 17V47H14V21H54V17H10ZM16 23V25.0312C21.918 29.9102 34.1602 40 35.1562 40.8438C36.25 41.7695 37.4023 41.9062 38 41.9062C38.5977 41.9062 39.75 41.7695 40.8438 40.8438C41.9258 39.9297 55.2539 28.9648 60 25.0625V23H16ZM16 27.625V48.2188L29.125 38.4375C25.2305 35.2227 19.7812 30.7422 16 27.625ZM60 27.6562C56.6055 30.4492 50.9609 35.0977 46.9062 38.4375L60 48.25V27.6562ZM30.6875 39.75L16 50.7188V53H60V50.75L45.3125 39.75C43.6367 41.1328 42.4414 42.1328 42.1562 42.375C40.5781 43.7109 38.8711 43.9062 38 43.9062C37.1289 43.9062 35.4219 43.7148 33.8438 42.375C33.5664 42.1406 32.3789 41.1484 30.6875 39.75Z" fill="black"/>
              </svg>
            </v-col>
            <v-col cols="9" md="9">
              <h3> Send Email </h3>
              <p>
                info@vertex-group.de
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-row>
            <v-col cols="3" md="3">
              <svg style="max-width: 100%;" width="76" height="70" viewBox="0 0 76 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M57 48.4492C57 49.0117 56.8555 49.7539 56.2891 50.543C56.2422 50.6055 51.5625 57 49 57L48.8477 56.9922C42.7969 56.6953 35.3125 52.125 26.5937 43.4062C17.8789 34.6914 13.3086 27.207 13.0078 21.1562L13.0039 21.0547C13.0039 21.0273 13 21 13 20.9727C13 18.3555 19.3945 13.7578 19.457 13.7109C21.1172 12.5273 22.9727 12.957 23.7383 14.0312C24.2656 14.7305 29.1055 22.1211 29.6445 23.0234C30.1992 23.9922 30.0937 25.3203 29.3828 26.5039C29 27.207 27.7461 29.3984 27.1602 30.4141C27.7852 31.3203 29.4648 33.5391 32.9883 36.9961C36.4531 40.4648 38.6797 42.1797 39.5937 42.832C40.6172 42.2422 42.8047 40.9922 43.5312 40.5977C44.8242 39.8906 46.1602 39.8086 47.0156 40.3789C47.8633 40.8867 55.3477 45.8164 55.9648 46.2617C56.5273 46.6602 56.8984 47.3398 56.9805 48.1172C56.9922 48.2187 57 48.3281 57 48.4492Z" fill="black"/>
                <path d="M76 35L68.5 41.9282L68.5 28.0718L76 35Z" fill="#EEB900"/>
                <rect x="1" y="1" width="68" height="68" stroke="#EEB900" stroke-width="2"/>
              </svg>
            </v-col>
            <v-col cols="9" md="9">
              <h3> Call Us </h3>
              <p>
                +49 2151 - 649 73 97
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {

}
</script>

<style>
#contact-us h2{
  text-align: center;
}
</style>
