var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-app-bar',{staticClass:"custom-header hidden-md-and-down",attrs:{"app":"","id":"header"}},[_c('v-container',[_c('v-toolbar-title',[_c('router-link',{attrs:{"to":"/","tag":"a"}},[_c('img',{attrs:{"src":require("../assets/logo.svg"),"width":"120","height":"46","alt":"VERTEX GmbH Logo"}})]),_c('v-toolbar-items',[_c('router-link',{attrs:{"to":"/","tag":"button"}},[_c('v-btn',{attrs:{"tile":""},on:{"click":function($event){return _vm.redirectOrScroll('/home/subtitle-block')}}},[_vm._v(" Home ")])],1),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{active: _vm.miningValue},attrs:{"tile":""},on:{"click":function($event){return _vm.redirectOrScroll('/mining/subtitle-block')}}},on),[_vm._v(" Mining "),_c('v-icon',[_vm._v("arrow_right")])],1)]}}]),model:{value:(_vm.miningValue),callback:function ($$v) {_vm.miningValue=$$v},expression:"miningValue"}},[_c('v-list',_vm._l((_vm.minings),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('v-btn',{attrs:{"tile":""},on:{"click":function($event){return _vm.redirectOrScroll(item.link)}}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)}),1)],1),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{active: _vm.metallurgyValue},on:{"click":function($event){return _vm.redirectOrScroll('/metallurgy/subtitle-block')}}},on),[_vm._v(" Metal Processing "),_c('v-icon',[_vm._v("arrow_right")])],1)]}}]),model:{value:(_vm.metallurgyValue),callback:function ($$v) {_vm.metallurgyValue=$$v},expression:"metallurgyValue"}},[_c('v-list',_vm._l((_vm.metallurgy),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":item.link,"tag":"button"}},[_c('v-btn',{attrs:{"tile":""},on:{"click":function($event){return _vm.redirectOrScroll(item.link)}}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)],1)}),1)],1),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{active: _vm.railroadValue},on:{"click":function($event){return _vm.redirectOrScroll('/railroad/subtitle-block')}}},on),[_vm._v(" Railroad "),_c('v-icon',[_vm._v("arrow_right")])],1)]}}]),model:{value:(_vm.railroadValue),callback:function ($$v) {_vm.railroadValue=$$v},expression:"railroadValue"}},[_c('v-list',_vm._l((_vm.railroad),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":item.link,"tag":"button"}},[_c('v-btn',[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)],1)}),1)],1),_c('router-link',{attrs:{"to":"/oilandgas","tag":"button"}},[_c('v-btn',[_vm._v(" Oil & Gas ")])],1),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{active: _vm.foodValue},on:{"click":function($event){return _vm.redirectOrScroll('/food/subtitle-block')}}},on),[_vm._v(" Food & Beverage "),_c('v-icon',[_vm._v("arrow_right")])],1)]}}]),model:{value:(_vm.foodValue),callback:function ($$v) {_vm.foodValue=$$v},expression:"foodValue"}},[_c('v-list',_vm._l((_vm.food),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('v-btn',{attrs:{"tile":""},on:{"click":function($event){return _vm.redirectOrScroll(item.link)}}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)}),1)],1),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{active: _vm.repairValue},on:{"click":function($event){return _vm.redirectOrScroll('/repair/subtitle-block')}}},on),[_vm._v(" Services "),_c('v-icon',[_vm._v("arrow_right")])],1)]}}]),model:{value:(_vm.repairValue),callback:function ($$v) {_vm.repairValue=$$v},expression:"repairValue"}},[_c('v-list',_vm._l((_vm.repair),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":item.link,"tag":"button"}},[_c('v-btn',{attrs:{"tile":""},on:{"click":function($event){return _vm.redirectOrScroll(item.link)}}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)],1)}),1)],1),_c('router-link',{attrs:{"to":"/kontakt","tag":"button"}},[_c('v-btn',[_vm._v(" Сontaсt ")])],1)],1)],1)],1)],1),_c('mobile-menu',{staticClass:"hidden-lg-and-up",attrs:{"show":_vm.drawer}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }