<template>
  <section>
    <general-block></general-block>
    <welcome-info></welcome-info>
    <category-tabs></category-tabs>
    <our-reasons></our-reasons>
    <our-partners></our-partners>
    <contact-us></contact-us>
  </section>
</template>

<script>
import CategoryTabs from '../components/CategoryTabs.vue'
import GeneralBlock from '../components/GeneralBlock.vue'
import OurReasons from '../components/OurReasons.vue'
import WelcomeInfo from '../components/WelcomeInfo.vue'
import OurPartners from '../components/OurPartners.vue'
import ContactUs from '../components/ContactUs.vue'

export default {
  name: 'Home',
  components: {
    GeneralBlock,
    WelcomeInfo,
    OurReasons,
    OurPartners,
    ContactUs,
    CategoryTabs
  },
  data () {
    return {
      img: '@/assets/logo.png'
    }
  },
  mounted () {
    if (Object.keys(this.$route.params).length > 0) {
      location.href = `${location.href}#${this.$route.params.id}`
    }
  },
  metaInfo: {
    title: 'VERTEX GmbH',
    titleTemplate: '%s - Materials & Equipment supplier',
    htmlAttrs: {
      lang: 'en',
      amp: true
    }
  }
}
</script>
