<template>
  <v-container class="d-flex d-sm-flex d-md-none mobile-slider">
    <v-row>
      <v-col cols="12">
        <v-card
          class="mx-auto mobile_accor"
          width="auto"
        >
          <v-list-group
            v-for="item in tabs"
            :key="item.title"
            link
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>
            <v-carousel>
              <v-carousel-item
                v-for="card in item.cards"
                :key="card.title"
              >
                <v-sheet height="100%" tile>
                  <v-row class="fill-height" align="center" justify="center">
                    <category-card
                      class="categoryCard"
                      :card_data="card"
                    ></category-card>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-list-group>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CategoryCard from '../components/CategoryCard'
export default {
  name: 'MobileCardSlider',
  components: {
    CategoryCard
  },
  breakpoint: {
    mobileBreakpoint: 'sm'
  },
  data: () => ({
    model: 0,
    tabs: [{
      title: 'Mining',
      cards: [{
        title: 'New Equipment & Components',
        text: 'Equipment solutions for Conveying, Materials Storage, Crushing, Screening, Compressors, Pumps, Motors, Instrumentation, Automation',
        link: '/mining/miningEquipment',
        imgLink: 'Icon/mining2.svg'
      }, {
        title: 'Spare & Wear Parts',
        text: 'Full range of spares and consumables for your mining operation.',
        link: '/mining/miningSpare',
        imgLink: 'Icon/mining1.svg'
      }, {
        title: 'Modernization &  Revamps',
        text: 'Improving your equipment`s availability and lifetime through modifications and repairs on/off site.',
        link: '/mining/miningModernization',
        imgLink: 'Icon/mining-4.svg'
      }]
    }, {
      title: 'Metal Processing',
      img: 'tabs/beam.svg',
      cards: [{
        title: 'Forging & Forming',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/metallurgy',
        imgLink: 'Icon/mining-4.svg'
      }, {
        title: 'Machining & Cutting',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/metallurgy',
        imgLink: 'Icon/mining-4.svg'
      }, {
        title: 'Welding',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/metallurgy',
        imgLink: 'Icon/mining-4.svg'
      }, {
        title: 'Furnaces',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/metallurgy',
        imgLink: 'Icon/mining-4.svg'
      }]
    }, {
      title: 'Railroad',
      img: 'tabs/001-train.svg',
      cards: [{
        title: 'Spare & Wear Parts',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/railroad',
        imgLink: 'Icon/mining-4.svg'
      }, {
        title: 'New Equipment and Components',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/railroad',
        imgLink: 'Icon/mining-4.svg'
      }, {
        title: 'Modernization and Revamps',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/railroad',
        imgLink: 'Icon/mining-4.svg'
      }]
    }, {
      title: 'Food & Beverage',
      img: 'tabs/Vvegan.svg',
      cards: [{
        title: 'Special equipment for production of food and beverage',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/food/foodSpecialEquip',
        imgLink: 'Icon/mining-4.svg'
      }, {
        title: 'Hydraulics / hydraulic parts / assemblies',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/food/foodHydraulics',
        imgLink: 'Icon/mining-4.svg'
      }, {
        title: 'Sensors and other measuring equipment',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/food/foodSensors',
        imgLink: 'Icon/mining-4.svg'
      }, {
        title: 'Laboratory equipment',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/food/foodLaboratory',
        imgLink: 'Icon/mining-4.svg'
      }, {
        title: 'Conveyor systems',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/food/foodConveyour',
        imgLink: 'Icon/mining-4.svg'
      }, {
        title: 'Electrical parts',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/food/foodElectrical',
        imgLink: 'Icon/mining-4.svg'
      }, {
        title: 'HVAC and ventilations systems',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/food/foodHvac',
        imgLink: 'Icon/mining-4.svg'
      }, {
        title: 'Motors',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/food/foodMotors',
        imgLink: 'Icon/mining-4.svg'
      }]
    }, {
      title: 'Services',
      img: 'tabs/Repair.svg',
      cards: [{
        title: 'Repair of Gearboxes, Bearings and Pumps',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/repair',
        imgLink: 'Icon/mining-4.svg'
      }, {
        title: 'Revamp of Motors and Compressors',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/repair',
        imgLink: 'Icon/mining-4.svg'
      }, {
        title: 'Inpections and Maintenance on Site',
        text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
        link: '/repair',
        imgLink: 'Icon/mining-4.svg'
      }]
    }]
  })
}
</script>

<style>
</style>
