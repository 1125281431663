<template>
  <v-card tile max-width="374">

      <v-row class="categoryCard-box">
        <v-col cols="3" sm="4">
          <v-img
            :src="require('../assets/' + card_data.imgLink)"
          ></v-img>
        </v-col>
        <v-col cols="9" sm="8">
          <h3>{{ card_data.title }}</h3>
        </v-col>
        <v-col cols="12">
          <v-card-text>
            {{ card_data.text }}
          </v-card-text>
            <v-btn tile @click="redirectOrScroll(card_data.link)">
              Read More <v-icon right> arrow_right </v-icon>
            </v-btn>
        </v-col>
      </v-row>

  </v-card>
</template>

<script>
export default {
  name: 'CategoryCard',
  computed: {
    options () {
      return {
        offset: 60
      }
    }
  },
  methods: {
    redirectOrScroll (link) {
      if (Object.keys(this.$route.params).length) {
        if (link === this.$route.path) {
          this.$vuetify.goTo('#' + this.$route.params.id, this.options)
        } else {
          this.$router.push(link)
          this.$vuetify.goTo('#' + this.$route.params.id, this.options)
        }
      } else {
        this.$router.push(link)
      }
    },
    getLink (link) {
      return link
    }
  },
  props: {
    card_data: {
      type: Object,
      default () {
        return {
          text: '',
          title: '',
          imgLink: ''
        }
      }
    }
  }
}
</script>

<style>
</style>
