<template>
  <section id="our-partners">
    <v-container>
      <v-row>
        <v-col cols="12" md="3">
          <p>
          Our selected <br> partners
          </p>
        </v-col>
        <v-col cols="12" md="3" class="partners-img">
          <img src="../assets/partners/gave.svg" alt="" />
        </v-col>
        <v-col cols="12" md="3" class="partners-img">
          <img src="../assets/partners/fkk.svg" alt="" />
        </v-col>
        <v-col cols="12" md="3" class="partners-img">
          <img src="../assets/partners/partex.svg" alt="" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>
