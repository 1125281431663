<template>
  <v-app>
    <div id="app">
      <app-header app-header></app-header>
      <router-view />
      <vertex-footer></vertex-footer>
    </div>
  </v-app>
</template>

<script>
import AppHeader from './components/AppHeader'
import VertexFooter from '@/components/VertexFooter'
export default {
  components: {
    VertexFooter,
    AppHeader
  }
}
</script>

<style lang="sass">
  @import "./sass/main.sass"
</style>
