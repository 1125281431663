<template>
  <section id="title-block">
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="8" lg="6">
            <p class="pre-title">Materials & Equipment Supplier</p>
            <div class="orange-line">
              <div class="line"></div>
            </div>
            <h1>VERTEX GmbH</h1>
            <p class="post-title">
              The most efficient and optimal equipment for your whole production
              chain
            </p>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {}
</script>

<style scoped>
#title-block {
  background: /*linear-gradient(
      270deg,
      rgba(15, 15, 15, 0.36) 0%,
      rgba(0, 0, 0, 0) 19.88%
    ),
    linear-gradient(90deg, rgba(15, 15, 15, 0.6) 0%, rgba(0, 0, 0, 0) 50%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 46.35%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 46.75%),*/
    url(../assets/Scrim.jpg);
}
</style>
