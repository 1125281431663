<template>
  <section id="our-reasons">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <h2><span class="bold">Our </span> competencies:</h2>
          <div class="orange-line left">
            <div class="orange"></div>
            <div class="line"></div>
          </div>
          <v-row>
            <v-col cols="1" sm="1" md="2" lg="1">
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.2699 2.54492C11.1229 2.54492 7.44238 4.55215 5.11726 7.63492H5.08992V7.66972C3.49924 9.79396 2.54492 12.42 2.54492 15.2699C2.54492 22.2827 8.25717 27.9949 15.2699 27.9949C22.2827 27.9949 27.9949 22.2827 27.9949 15.2699C27.9949 8.25717 22.2827 2.54492 15.2699 2.54492ZM19.0874 5.83304C22.8223 7.33889 25.4499 10.985 25.4499 15.2699C25.4499 17.9267 24.4327 20.3323 22.7757 22.1419C22.4501 21.1116 21.4983 20.3599 20.3599 20.3599H19.0874V16.5424C19.0874 15.84 18.5173 15.2699 17.8149 15.2699H11.4524V12.7249H12.7249C13.4273 12.7249 13.9974 12.1548 13.9974 11.4524V8.90742H16.5424C17.9485 8.90742 19.0874 7.76853 19.0874 6.36242V5.83304ZM5.35337 12.9884L7.63492 15.2699L11.4524 19.0874V20.3599C11.4524 21.766 12.5913 22.9049 13.9974 22.9049V25.3629C8.96567 24.7388 5.08992 20.4753 5.08992 15.2699C5.08992 14.4838 5.18603 13.7226 5.35337 12.9884Z" fill="#EEB900"/>
              </svg>
            </v-col>
            <v-col cols="11" sm="11" md="10" lg="11">
              <h3>What makes us different</h3>
              <p>Flexible multi-language team with broaden scope of industry relevant knowlege and skills. Out-of-the-box thinking cultivated within the company to address the most difficult issues of our customers.</p>
            </v-col>
            <v-col cols="1" sm="1" md="2" lg="1">
              <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.2092 29.4285L22.7622 18.9089L30.9265 11.8345L20.164 10.9111L15.9598 0.993164L11.7557 10.9111L0.993164 11.8345L9.15748 18.9089L6.71043 29.4285L15.9598 23.8492L25.2092 29.4285Z" fill="#EEB900"/>
              </svg>
            </v-col>
            <v-col cols="11" sm="11" md="10" lg="11">
              <h3>Individual approach to every single client</h3>
              <p>Taylor-made solutions based on customer's industry, production issues and organizational structure.</p>
            </v-col>
            <v-col cols="1" sm="1" md="2" lg="1">
              <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.72408 22.483H12.8136V0.496582H7.72408V22.483ZM0.0898438 22.483H5.17935V11.4898H0.0898438V22.483ZM15.3584 22.483V7.36733H20.4479V22.483H15.3584Z" fill="#EEB900"/>
              </svg>
            </v-col>
            <v-col cols="11" sm="11" md="10" lg="11">
              <h3>Quality of Service</h3>
              <p>Post-sales is as much important for us. We strive to ensure the quality of our suppliers and supplied products in order to establish long-term und mutually trusted relationships with all our customers.</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="reasonImg" cols="12" md="6">
          <v-img src="../assets/Big.jpg" height="525"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {}
</script>

<style>
</style>
