<template>
  <v-footer id="footer">
    <v-container>
      <v-row>
        <v-col cols='12' md='3'>
          <h2>VERTEX GmbH</h2>
          <p>
            The most efficient and optimal equipment for your whole production
            chain
          </p>
        </v-col>
        <v-col cols='12' md='3' offset-md='1'>
          <ul class='my-2 link-list'>
            <li
              v-for='item in links'
              :key='item.title'
            >
              <router-link :to="item.link">
                {{ item.title }}
              </router-link>
            </li>
          </ul>
        </v-col>
        <v-col cols='12' md='3' offset-md='1'>
          <p>Bogenstr. 15</p>
          <p>47799 Krefeld</p>
          <p>Deutschland / Germany</p>
          <p>Tel.: +49 2151 - 649 73 97</p>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="bottom-footer">
      <v-row>
        <v-col cols='12' sm="6">
          <p>
           @ {{ new Date().getFullYear() }} — VERTEX GmbH. All right reserved.
          </p>
        </v-col>
        <v-col class="bottom-footer-links" cols='12' sm="6">
          <router-link to="/imprint/subtitle-block" tag="a">Imprint</router-link>
          <router-link to="/privacy/subtitle-block" tag="a">Privacy Policy</router-link>
          <router-link to="/terms/subtitle-block" tag="a">Terms of Service</router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  computed: {
    options () {
      return {
        offset: 60
      }
    }
  },
  methods: {
    redirectOrScroll (link) {
      if (Object.keys(this.$route.params).length) {
        if (link === this.$route.path) {
          this.$vuetify.goTo('#' + this.$route.params.id, this.options)
        } else {
          this.$router.push(link)
          this.$vuetify.goTo('#' + this.$route.params.id, this.options)
        }
      } else {
        this.$router.push(link)
      }
    },
    getLink (link) {
      return link
    }
  },
  data: () => ({
    links: [{
      title: 'Home',
      link: '/'
    }, {
      title: 'Mining',
      link: '/mining/subtitle-block',
      punkts: [{
        title: 'Расходные части',
        link: '/home'
      }, {
        title: 'Промышленное оборудование и запчасти',
        link: '/home'
      }, {
        title: 'Электрика и системы автоматизации',
        link: '/home'
      }, {
        title: 'Ремонт и переоснащение оборудования',
        link: '/home'
      }]
    }, {
      title: 'Metallurgy',
      link: '/metallurgy/subtitle-block',
      punkts: [{
        title: 'Оборудование для металлобработки',
        link: '/home'
      }, {
        title: 'Металлорежущее оборудование',
        link: '/home'
      }, {
        title: 'Оборудование и расходные материалы для сварки',
        link: '/home'
      }, {
        title: 'Оборудование и расходные материалы для термообработки',
        link: '/home'
      }]
    }, {
      title: 'Railroad',
      link: '/railroad/subtitle-block',
      punkts: [{
        title: 'Оборудование',
        link: '/home'
      }, {
        title: 'Металлорежущее',
        link: '/home'
      }, {
        title: 'Электрика',
        link: '/home'
      }, {
        title: 'Ремонт',
        link: '/home'
      }]
    }, {
      title: 'Food & Beverage',
      link: '/food/subtitle-block',
      punkts: [{
        title: 'FoodРасходные',
        link: '/home'
      }, {
        title: 'Foodоборудование',
        link: '/home'
      }, {
        title: 'Foodсистемы',
        link: '/home'
      }, {
        title: 'Foodпереоснащение',
        link: '/home'
      }]
    }, {
      title: 'Services',
      link: '/repair/subtitle-block',
      punkts: [{
        title: 'Repairчасти',
        link: '/home'
      }, {
        title: 'Repairзапчасти',
        link: '/home'
      }, {
        title: 'Repairавтоматизации',
        link: '/home'
      }, {
        title: 'Repairоборудования',
        link: '/home'
      }]
    }, {
      title: 'Сontaсt',
      link: '/kontakt/subtitle-block'
    }]
  })
}
</script>

<style>
</style>
