<template>
  <section id="coreBusiness" class="gray">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2><span class="bold">Our</span> Core Business</h2>
          <div class="orange-line center">
            <div class="orange"></div>
            <div class="line"></div>
          </div>
          <p class="center" >Based on our long-term experience and team expertise we provide solutions to the following industries and offer various after-sales services:</p>
        </v-col>
      </v-row>
      <div class="d-none d-md-block">
      <v-tabs
        v-model='tab'
        grow
      >
        <v-tab
          v-for='item in tabs'
          :key='item.title'
          :id="item.title + '-tab'"
        >
          <div class="iconTabs"></div>
          {{ item.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model='tab'>
        <v-tab-item
          v-for='item in tabs'
          :key='item.title'
        >
          <v-row justify="center">
            <v-col cols="12" sm="6" md="6" lg="3" v-for='card in item.cards' :key='card.title'>
              <category-card
                class="categoryCard"
                :card_data="card"
              ></category-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
      </div>
    </v-container>
    <mobile-card-slider></mobile-card-slider>
  </section>
</template>

<script>
import CategoryCard from '../components/CategoryCard.vue'
import MobileCardSlider from '@/components/MobileCardSlider'

export default {
  components: {
    MobileCardSlider,
    CategoryCard
  },
  data () {
    return {
      tab: null,
      tabs: [{
        title: 'Mining',
        img: 'tabs/002-mining-cart.svg',
        cards: [{
          title: 'New Equipment & Components',
          text: 'Equipment solutions for Conveying, Materials Storage, Crushing, Screening, Compressors, Pumps, Motors, Instrumentation, Automation',
          link: '/mining/miningEquipment',
          imgLink: 'Icon/mining2.svg'
        }, {
          title: 'Spare & Wear Parts',
          text: 'Full range of spares and consumables for your mining operation.',
          link: '/mining/miningSpare',
          imgLink: 'Icon/mining1.svg'
        }, {
          title: 'Modernization &  Revamps',
          text: 'Improving your equipment`s availability and lifetime through modifications and repairs on/off site.',
          link: '/mining/miningModernization',
          imgLink: 'Icon/mining-4.svg'
        }]
      }, {
        title: 'Metal Processing',
        img: 'tabs/beam.svg',
        cards: [{
          title: '',
          text: 'Section is under construction',
          imgLink: 'Icon/mining-4.svg'
        }]
      }, {
        title: 'Railroad',
        img: 'tabs/001-train.svg',
        cards: [{
          title: '',
          text: 'Section is under construction',
          imgLink: 'Icon/mining-4.svg'
        }]
      }, {
        title: 'Food & Beverage',
        img: 'tabs/Vvegan.svg',
        cards: [{
          title: 'Swivel joints and other metal components',
          text: 'Own production of connecting elements from different metals (steel, stainless steel, aluminum, etc.) for equipment of European and American OEMs.',
          link: '/food/foodMotors',
          imgLink: 'Icon/EQUIPMENT FOR FOOD.svg'
        }, {
          title: 'Special equipment for production',
          text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
          link: '/food/foodSpecialEquip',
          imgLink: 'Icon/HYDRAULICS.svg'
        }, {
          title: 'Hydraulics, hydraulic parts and assemblies',
          text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
          link: '/food/foodHydraulics',
          imgLink: 'Icon/MEASURING EQUIPMENT.svg'
        }, {
          title: 'Measuring equipment and sensors',
          text: 'All types of measuring equipment for food and beverage industry to ensure efficiency and safety of all production processes.',
          link: '/food/foodSensors',
          imgLink: 'Icon/LABORATORY.svg'
        }, {
          title: 'Laboratory equipment',
          text: 'Different types of laboratory equipment and conveyor systems accessories for the beverage industry',
          link: '/food/foodLaboratory',
          imgLink: 'Icon/CONVEYOR SYSTEMS.svg'
        }, {
          title: 'Industrial pumps and compressors',
          text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
          link: '/food/foodHvac',
          imgLink: 'Icon/ELECTRICAL PARTS.svg'
        }]
      }, {
        title: 'Services',
        img: 'tabs/Repair.svg',
        cards: [{
          title: 'Retrofit and Repair',
          text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
          link: 'repair/metalcut',
          imgLink: 'Icon/mining-4.svg'
        }, {
          title: 'Swivel joints and other metal components',
          text: 'Thanks to established relationships with leading global manufacturers of general industrial equipment we are actively developing and expanding our production and sales activities.',
          link: 'repair/foodMotors',
          imgLink: 'Icon/mining-4.svg'
        }]
      }]
    }
  }
}
</script>

<style>
.iconTabs{
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-color: black;
}
.v-tab--active .iconTabs{
  background-color: white;
}
#Mining-tab .iconTabs{
  mask-image: url("../assets/tabs/mining-cart.svg");
}
#Metal\ Processing-tab .iconTabs{
  mask-image: url("../assets/tabs/beam.svg");
}
#Railroad-tab .iconTabs{
  mask-image: url("../assets/tabs/train.svg");
}
#Food\ \&\ Beverage-tab .iconTabs{
  mask-image: url("../assets/tabs/Vvegan.svg");
}
#Services-tab .iconTabs{
  mask-image: url("../assets/tabs/Repair.svg");
}
</style>
