<template>
  <section>
    <v-app-bar app id="header" class="custom-header hidden-md-and-down">
      <v-container>
      <v-toolbar-title>
        <router-link to="/" tag="a" >
            <img src="../assets/logo.svg" width="120" height="46" alt="VERTEX GmbH Logo">
        </router-link>
        <v-toolbar-items>
            <router-link to="/" tag="button">
              <v-btn tile @click="redirectOrScroll('/home/subtitle-block')">
               Home
              </v-btn>
            </router-link>
          <v-menu open-on-hover offset-y v-model="miningValue">
            <template v-slot:activator="{ on }">
            <v-btn tile v-on="on" :class="{active: miningValue}" @click="redirectOrScroll('/mining/subtitle-block')">
             Mining <v-icon>arrow_right</v-icon>
            </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in minings"
                :key="index"
              >
                <v-list-item-title>
                    <v-btn tile @click="redirectOrScroll(item.link)"> {{ item.title }} </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu open-on-hover offset-y v-model="metallurgyValue">
            <template v-slot:activator="{ on }">
            <v-btn v-on="on" :class="{active: metallurgyValue}" @click="redirectOrScroll('/metallurgy/subtitle-block')">
              Metal Processing <v-icon>arrow_right</v-icon>
            </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in metallurgy"
                :key="index"
              >
                <v-list-item-title> <router-link :to="item.link" tag="button"> <v-btn tile @click="redirectOrScroll(item.link)"> {{ item.title }} </v-btn>  </router-link></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu open-on-hover offset-y v-model="railroadValue">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :class="{active: railroadValue}" @click="redirectOrScroll('/railroad/subtitle-block')">
                Railroad <v-icon>arrow_right</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in railroad"
                :key="index"
              >
                <v-list-item-title>
                  <router-link :to="item.link" tag="button">
                    <v-btn> {{ item.title }} </v-btn>
                  </router-link>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <router-link to="/oilandgas" tag="button">
            <v-btn>
              Oil & Gas
            </v-btn>
          </router-link>
          <v-menu open-on-hover offset-y v-model="foodValue">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :class="{active: foodValue}" @click="redirectOrScroll('/food/subtitle-block')">
                Food & Beverage <v-icon>arrow_right</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in food"
                :key="index"
              >
                <v-list-item-title>
                  <v-btn tile @click="redirectOrScroll(item.link)"> {{ item.title }} </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu open-on-hover offset-y v-model="repairValue">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :class="{active: repairValue}" @click="redirectOrScroll('/repair/subtitle-block')">
                Services <v-icon>arrow_right</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in repair"
                :key="index"
              >
                <v-list-item-title>
                  <router-link :to="item.link" tag="button">
                    <v-btn tile @click="redirectOrScroll(item.link)"> {{ item.title }} </v-btn>
                  </router-link>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <router-link to="/kontakt" tag="button">
            <v-btn>
              Сontaсt
            </v-btn>
          </router-link>
        </v-toolbar-items>
      </v-toolbar-title>
      </v-container>
    </v-app-bar>
    <mobile-menu class="hidden-lg-and-up" :show="drawer"></mobile-menu>
  </section>
</template>

<script>
import MobileMenu from '@/components/MobileMenu'

export default {
  components: { MobileMenu },
  computed: {
    options () {
      return {
        offset: 60
      }
    }
  },
  methods: {
    redirectOrScroll (link) {
      if (Object.keys(this.$route.params).length) {
        if (link === this.$route.path) {
          this.$vuetify.goTo('#' + this.$route.params.id, this.options)
        } else {
          this.$router.push(link)
          this.$vuetify.goTo('#' + this.$route.params.id, this.options)
        }
      } else {
        this.$router.push(link)
      }
    },
    getLink (link) {
      return link
    }
  },
  data: () => ({
    drawer: false,
    minings: [{
      title: 'New Equipment & Components',
      link: '/mining/miningEquipment'
    }, {
      title: 'Spare & Wear Parts',
      link: '/mining/miningSpare'
    }, {
      title: 'Modernization & Revamps',
      link: '/mining/miningModernization'
    }],
    /* metallurgy: [{
        title: 'Forging',
        link: '/metallurgy'
      }, {
        title: 'Machining and Cutting',
        link: '/metallurgy'
      }, {
        title: 'Welding',
        link: '/metallurgy'
      }, {
        title: 'Forming',
        link: '/metallurgy'
      }, {
        title: 'Furnaces',
        link: '/metallurgy'
      }],
    railroad: [{
      title: 'Spare & Wear Parts',
      link: '/railroad'
    }, {
      title: 'New Equipment and Components',
      link: '/railroad'
    }, {
      title: 'Modernization and Revamps',
      link: '/railroad'
    }], */
    food: [{
      title: 'Swivel Joints and other metal components',
      link: '/food/foodMotors'
    }, {
      title: 'Special equipment for production of food and beverage',
      link: '/food/foodSpecialEquip'
    }, {
      title: 'Hydraulics / hydraulic parts / assemblies',
      link: '/food/foodHydraulics'
    }, {
      title: 'Measuring equipment and sensors',
      link: '/food/foodSensors'
    }, {
      title: 'Laboratory equipment',
      link: '/food/foodLaboratory'
    }, {
      title: 'Industrial Pumps and compressors',
      link: '/food/foodHvac'
    }],
    repair: [{
      title: 'Retrofit and Repair',
      link: '/repair/metalcut'
    },
    {
      title: 'Swivel Joints and other metal components',
      link: '/repair/foodMotors'
    }],
    miningValue: null,
    metallurgyValue: null,
    railroadValue: null,
    foodValue: null,
    repairValue: null
  })
}
</script>

<style scoped>
  .v-btn{
    cursor: pointer;
  }
  .v-btn.active{
    color: #EEB900;
  }
  .v-btn.active .v-icon {
    transform: rotate(90deg);
    color: #EEB900;
  }
</style>
